import React from "react";
import AuthenticationWrapper from "../../components/AuthenticationWrapper";
import MainLayout from "../../layouts/MainLayout";
import ManageLayout from "../../layouts/ManageLayout";
import EditTranslation from "../../routes/translations/EditTranslation";
import TranslationsIndex from "../../routes/translations/TranslationsIndex";
import { TRANSLATIONS_ROOT, PATH_TRANSLATION_KEY } from "../routes";

const TranslationRouter = {
    path: TRANSLATIONS_ROOT,
    children: [
        {
            element: (
                <AuthenticationWrapper>
                    <MainLayout />
                </AuthenticationWrapper>
            ),
            children: [
                {
                    index: true,
                    element: <TranslationsIndex />,
                },
                {
                    path: `${PATH_TRANSLATION_KEY}/:translation_key`,
                    element: <TranslationsIndex />,
                },
                {
                    path: `${PATH_TRANSLATION_KEY}/:translation_key/:locale`,
                    element: <TranslationsIndex />,
                },
            ],
        },
        {
            path: ":translationId/edit",
            element: (
                <AuthenticationWrapper>
                    <ManageLayout />
                </AuthenticationWrapper>
            ),
            children: [{ index: true, element: <EditTranslation /> }],
            handle: {
                crumb: [
                    { label: "Translations", to: TRANSLATIONS_ROOT },
                    { label: "Edit", to: null },
                ],
            },
        },
    ],
};

export default TranslationRouter;
