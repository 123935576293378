import React from "react";
import AuthenticationWrapper from "../../components/AuthenticationWrapper";
import MainLayout from "../../layouts/MainLayout";
import ManageLayout from "../../layouts/ManageLayout";
import CMSCategoriesIndex from "../../routes/boost/cms/categories/CMSCategoriesIndex";
import CreateCategory from "../../routes/boost/cms/categories/create/CreateCategory";
import EditCategory from "../../routes/boost/cms/categories/edit/EditCategory";
import CMSHeadingsIndex from "../../routes/boost/cms/heading/CMSHeadingsIndex";
import CreateHeading from "../../routes/boost/cms/heading/create/CreateHeading";
import EditHeading from "../../routes/boost/cms/heading/edit/EditHeading";
import CMSTemplatesIndex from "../../routes/boost/cms/templates/CMSTemplatesIndex";
import CreateTemplate from "../../routes/boost/cms/templates/create/CreateTemplate";
import EditTemplate from "../../routes/boost/cms/templates/edit/EditTemplate";
import {
    BOOST_CMS_ROOT,
    PATH_BOOST_HEADINGS,
    BOOST_CMS_HEADINGS_ROOT,
    ACTION_CREATE,
    PATH_BOOST_CATEGORIES,
    BOOST_CMS_CATEGORIES_ROOT,
    PATH_BOOST_TEMPLATES,
    BOOST_CMS_TEMPLATES_ROOT,
} from "../routes";

const BoostCMSRouter = {
    path: BOOST_CMS_ROOT,
    children: [
        {
            path: PATH_BOOST_HEADINGS,
            element: (
                <AuthenticationWrapper>
                    <MainLayout />
                </AuthenticationWrapper>
            ),
            children: [
                {
                    index: true,
                    element: <CMSHeadingsIndex />,
                },
            ],
        },
        {
            path: PATH_BOOST_HEADINGS,
            element: (
                <AuthenticationWrapper>
                    <ManageLayout />
                </AuthenticationWrapper>
            ),
            children: [
                {
                    path: ":headingId/edit",
                    element: <EditHeading />,
                    handle: {
                        crumb: [
                            { label: "Headings", to: BOOST_CMS_HEADINGS_ROOT },
                            { label: "Edit", to: null },
                        ],
                    },
                },
                {
                    path: ACTION_CREATE,
                    element: <CreateHeading />,
                    handle: {
                        crumb: [
                            { label: "Headings", to: BOOST_CMS_HEADINGS_ROOT },
                            { label: "Create", to: null },
                        ],
                    },
                },
            ],
        },

        {
            path: PATH_BOOST_CATEGORIES,
            element: (
                <AuthenticationWrapper>
                    <MainLayout />
                </AuthenticationWrapper>
            ),
            children: [
                {
                    index: true,
                    element: <CMSCategoriesIndex />,
                },
                {
                    path: `${PATH_BOOST_HEADINGS}/:headingId`,
                    element: <CMSCategoriesIndex />,
                },
            ],
        },

        {
            path: PATH_BOOST_CATEGORIES,
            element: (
                <AuthenticationWrapper>
                    <ManageLayout />
                </AuthenticationWrapper>
            ),
            children: [
                {
                    path: ":categoryId/edit",
                    element: <EditCategory />,
                    handle: {
                        crumb: [
                            { label: "Categories", to: BOOST_CMS_CATEGORIES_ROOT },
                            { label: "Edit", to: null },
                        ],
                    },
                },
                {
                    path: ACTION_CREATE,
                    element: <CreateCategory />,
                    handle: {
                        crumb: [
                            { label: "Categories", to: BOOST_CMS_CATEGORIES_ROOT },
                            { label: "Create", to: null },
                        ],
                    },
                },
            ],
        },
        {
            path: PATH_BOOST_TEMPLATES,
            element: (
                <AuthenticationWrapper>
                    <MainLayout />
                </AuthenticationWrapper>
            ),
            children: [
                {
                    index: true,
                    element: <CMSTemplatesIndex />,
                },
                {
                    path: `${PATH_BOOST_HEADINGS}/:headingId`,
                    element: <CMSTemplatesIndex />,
                },
                {
                    path: `${PATH_BOOST_CATEGORIES}/:categoryId`,
                    element: <CMSTemplatesIndex />,
                },
            ],
        },
        {
            path: PATH_BOOST_TEMPLATES,
            element: (
                <AuthenticationWrapper>
                    <ManageLayout />
                </AuthenticationWrapper>
            ),
            children: [
                {
                    path: ":templateId/edit",
                    element: <EditTemplate />,
                    handle: {
                        crumb: [
                            { label: "Templates", to: BOOST_CMS_TEMPLATES_ROOT },
                            { label: "Edit", to: null },
                        ],
                    },
                },
                {
                    path: ACTION_CREATE,
                    element: <CreateTemplate />,
                    handle: {
                        crumb: [
                            { label: "Templates", to: BOOST_CMS_TEMPLATES_ROOT },
                            { label: "Create", to: null },
                        ],
                    },
                },
            ],
        },
    ],
};

export default BoostCMSRouter;
