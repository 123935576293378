import { FormControl } from "baseui/form-control";
import { RadioGroup, Radio } from "baseui/radio";
import { Select } from "baseui/select";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "baseui/checkbox";
import { SchedulerPeriod } from "../../constants/enums";
import buildIntervals from "../../helpers/buildIntervals";
import {
    PERIOD_OPTIONS,
    MONTH_POS_OPTIONS,
    WEEKDAY_OPTIONS,
    DURATION_OPTION_SETS,
    LIFETIME_OPTIONS,
} from "../../constants/form-constants";

const HOUR_MIN_OPTIONS = buildIntervals(15);

const Scheduler: React.FC = () => {
    const { control, watch } = useFormContext();
    const period: SchedulerPeriod = watch("boost_settings.scheduler.period");

    return (
        <div className="p-4 my-4 border rounded-lg border-neutral-200">
            <span className="pb-2 font-bold">Scheduler settings</span>
            <Controller
                name="boost_settings.scheduler.period"
                control={control}
                render={({ field: { value: fieldVal, onChange } }) => (
                    <FormControl label={() => "How often should it send?"}>
                        <RadioGroup
                            value={fieldVal}
                            onChange={(e) => onChange(e.target.value)}
                            align="horizontal"
                        >
                            {PERIOD_OPTIONS.map(({ label, value }) => (
                                <Radio key={value} value={value}>
                                    {label}
                                </Radio>
                            ))}
                        </RadioGroup>
                    </FormControl>
                )}
            />

            {period === SchedulerPeriod.Monthly && (
                <Controller
                    name="boost_settings.scheduler.month_pos"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormControl label={() => "First or Last"}>
                            <Select
                                searchable={false}
                                clearable={false}
                                value={[
                                    MONTH_POS_OPTIONS?.find((p) => p?.id === value) ||
                                        MONTH_POS_OPTIONS[0],
                                ]}
                                onChange={(option) => onChange(option.value?.[0]?.id)}
                                options={MONTH_POS_OPTIONS}
                            />
                        </FormControl>
                    )}
                />
            )}

            {period !== SchedulerPeriod.Daily && (
                <Controller
                    name="boost_settings.scheduler.week_day"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormControl label={() => "Day of week"}>
                            <Select
                                searchable={false}
                                clearable={false}
                                value={[
                                    WEEKDAY_OPTIONS?.find((p) => p?.id === value) ||
                                        WEEKDAY_OPTIONS[0],
                                ]}
                                options={WEEKDAY_OPTIONS}
                                onChange={(option) => onChange(option.value?.[0]?.id)}
                            />
                        </FormControl>
                    )}
                />
            )}

            <Controller
                name="boost_settings.scheduler.time_of_day"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <FormControl label={() => "Time of Day"}>
                        <Select
                            clearable={false}
                            labelKey="label"
                            valueKey="value"
                            value={[
                                HOUR_MIN_OPTIONS?.find((p) => p.value === value) ||
                                    HOUR_MIN_OPTIONS[0],
                            ]}
                            onChange={(e) => onChange(e.value?.[0].value)}
                            options={HOUR_MIN_OPTIONS}
                        />
                    </FormControl>
                )}
            />

            <Controller
                name="boost_settings.run_duration"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <FormControl
                        label="Response Window"
                        caption={() =>
                            "This is how long the recipients will have to respond to the boost. After the response window has ended, recipients will not be able to respond.                    "
                        }
                    >
                        <Select
                            clearable={false}
                            searchable={false}
                            value={[
                                DURATION_OPTION_SETS[period]?.find((p) => p?.id === value) ||
                                    DURATION_OPTION_SETS[period]?.[0],
                            ]}
                            options={DURATION_OPTION_SETS[period]}
                            onChange={(e) => onChange(e.value?.[0]?.id)}
                        />
                    </FormControl>
                )}
            />

            {period === SchedulerPeriod.Daily && (
                <>
                    <Controller
                        name="boost_settings.duration"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FormControl
                                label={() => "Boost Lifetime"}
                                caption={() =>
                                    "This is how long the boost will run for. I.E. every day for 2 weeks."
                                }
                            >
                                <Select
                                    value={[
                                        LIFETIME_OPTIONS?.find((p) => p?.id === value) ||
                                            LIFETIME_OPTIONS,
                                    ]}
                                    options={LIFETIME_OPTIONS}
                                    onChange={(e) => onChange(e.value?.[0]?.id)}
                                />
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="boost_settings.scheduler.include_weekends"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}>
                                Include Weekends
                            </Checkbox>
                        )}
                    />
                </>
            )}
        </div>
    );
};

export default Scheduler;
