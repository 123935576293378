import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { API_BACKOFFICE_MERGE_GROUPS } from "../../constants/api-endpoints";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";
import { BE_MergeGroup } from "../../types/MergeGroups";

const useGetMergeGroups = ({
    customerId,
}: {
    customerId: string | undefined;
}): UseQueryResult<BE_MergeGroup[], { cause: number }> =>
    useQuery(
        ["merge-groups", customerId],
        () => {
            if (!customerId) return Promise.resolve([]);

            const url = new URL(API_BACKOFFICE_MERGE_GROUPS({ id: customerId }));

            const request = new AuthenticatedMoApiRequest(url);

            return FetchResponse(request);
        },
        {
            enabled: !!customerId,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    );

export default useGetMergeGroups;
