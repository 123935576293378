import { Value } from "baseui/select";
import React, { useState } from "react";
import { FormControl } from "baseui/form-control";
import { Spinner } from "baseui/spinner";
import { styled } from "baseui";
import IndexTitle from "../../components/IndexTitle";
import useGetMergeGroups from "../../queries/merge-dev/useGetMergeGroups";
import useGetMoGroups from "../../queries/merge-dev/useGetMoGroups";
import { BE_MergeGroup, BE_MoGroup, MergeGroupsFormValues } from "../../types/MergeGroups";
import MergeGroupMappingsForm from "./MergeGroupMappingsForm";
import CustomerSelect from "../../components/selects/CustomerSelect";
import MergeOnboardForm from "./MergeOnboardForm";

const StyledMergeGroupContainer = styled("main", ({ $theme }) => ({
    maxWidth: "1200px",
    margin: "0 auto",
    padding: $theme.sizing.scale800,
}));

const transformApiToForm = (groupArray: BE_MergeGroup[], moGroups: BE_MoGroup[]) => {
    if (!groupArray.length || !moGroups.length) return {} as MergeGroupsFormValues;
    return groupArray.reduce((acc: MergeGroupsFormValues, item) => {
        acc[item.id] = {
            type_name: item.type_name,
            mapped_group_category_id: item.mapped_group_category_id || "",
            mapped_group_category_label:
                moGroups.find(
                    (moGroup) => moGroup.service_group_id === item?.mapped_group_category_id,
                )?.name || "",
        };
        return acc;
    }, {});
};

const MergeGroupMappings = () => {
    const [customer, setCustomer] = useState<Value | undefined>(undefined);

    const {
        data: mergeGroups,
        isSuccess: mergeGroupsLoaded,
        error,
        isFetching: mergeGroupsFetching,
    } = useGetMergeGroups({ customerId: customer?.[0]?.service_id as string });

    const {
        data: moGroupCategories,
        isSuccess: moGroupCategoriesLoaded,
        isFetching: moGroupCategoriesFetching,
    } = useGetMoGroups({ customerMongoId: customer?.[0]?.id as string });

    const isFetching = mergeGroupsFetching || moGroupCategoriesFetching;
    const isSuccess = mergeGroupsLoaded && moGroupCategoriesLoaded;

    const defaultValues = transformApiToForm(mergeGroups || [], moGroupCategories || []);

    const customerId = customer?.[0]?.service_id as string;

    const hasNotOnboarded =
        (error && error?.cause === 404) ||
        (mergeGroups && mergeGroups?.length === 0) ||
        mergeGroups === null;

    return (
        <StyledMergeGroupContainer>
            <IndexTitle title="Merge.dev Group Mappings" />
            <FormControl label="Customer">
                <CustomerSelect value={customer} onChange={setCustomer} />
            </FormControl>

            {!customer && <span>Please select a customer to begin editing mappings.</span>}
            {isFetching && customer && <Spinner />}
            {error && (error?.cause !== 404 || error?.cause === undefined) && (
                <span>Something went wrong, please try again later.</span>
            )}
            {hasNotOnboarded && <MergeOnboardForm customerId={customerId} />}
            {customer && isSuccess && moGroupCategories && moGroupCategories?.length === 0 && (
                <span>
                    This customer has no groupings assigned in Mo, please add at least one and try
                    again.
                </span>
            )}
            {customer &&
                isSuccess &&
                moGroupCategories &&
                moGroupCategories?.length > 0 &&
                customerId &&
                mergeGroups &&
                mergeGroups.length > 0 && (
                    <MergeGroupMappingsForm
                        options={moGroupCategories || []}
                        groups={mergeGroups || []}
                        defaultValues={defaultValues}
                        customerId={customerId}
                    />
                )}
        </StyledMergeGroupContainer>
    );
};

export default MergeGroupMappings;
