import React from "react";
import { useCreateTemplate } from "../../../../../queries/boost_cms/useBoostCMSTemplates";
import {
    Langs,
    ResponseType,
    SchedulerMonthPos,
    SchedulerPeriod,
    SchedulerWeekdays,
} from "../../../../../constants/enums";
import TemplateForm from "../../../../../components/templates/TemplateForm";
import { DURATION_OPTION_SETS } from "../../../../../constants/form-constants";

const DEFAULT_VALUES = {
    author: "Team Mo",
    title: "",
    published: false,
    archived: false,
    root_locale: Langs.EN,
    message: "",
    value_proposition: "",
    cadence_hint: "",
    audience_hint: "",
    boost_settings: {
        response_type: ResponseType.POST,
        style: {
            emoji: "🥳",
            colour: "#324436",
        },
        scheduler: {
            week_day: SchedulerWeekdays.Friday,
            month_pos: SchedulerMonthPos.First,
            period: SchedulerPeriod.Weekly,
            time_of_day: "09:00",
            include_weekends: false,
        },
        duration: 0,
        run_duration: DURATION_OPTION_SETS[SchedulerPeriod.Weekly][0].id,
    },
    category_ids: [],
};

const CreateTemplate: React.FC = () => {
    const { mutate: createTemplate, isLoading: isCreateTemplateLoading } = useCreateTemplate();

    return (
        <div className="grid grid-cols-12 gap-10">
            <div className="col-start-5 col-span-full">
                <h1 className="pb-2 mb-6 text-4xl font-bold border-b border-b-gray-400">
                    Create Template
                </h1>
                <TemplateForm
                    isSubmitting={isCreateTemplateLoading}
                    isEdit={false}
                    initialValues={DEFAULT_VALUES}
                    onSubmit={(data) => createTemplate(data)}
                />
            </div>
        </div>
    );
};

export default CreateTemplate;
