import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BACKOFFICE_MERGE_ONBOARD } from "../../constants/api-endpoints";
import { addErrorToast, addSuccessToast } from "../../helpers/addToast";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";

interface OnboardPayload {
    customer_id: string;
    admin: string;
    merge_linked_account_id: string;
}

const useOnboard = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (payload: OnboardPayload) => {
            const url = new URL(API_BACKOFFICE_MERGE_ONBOARD);

            const request = new AuthenticatedMoApiRequest(url, {
                method: "POST",
                payload: payload as Partial<FormData>,
            });

            return FetchResponse(request);
        },
        {
            onSuccess: () => {
                addSuccessToast("Onboarded successfully!");
                queryClient.invalidateQueries(["merge-groups"]);
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );
};

export default useOnboard;
