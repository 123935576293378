import React from "react";
import AuthenticationWrapper from "../../components/AuthenticationWrapper";
import MainLayout from "../../layouts/MainLayout";
import { MERGE_DEV_ROOT, MERGE_DEV_GROUP_MAPPINGS } from "../routes";
import MergeGroupMappings from "../../routes/merge-group-mappings/MergeGroupMappings";

const MergeDevRouter = {
    path: MERGE_DEV_ROOT,
    children: [
        {
            element: (
                <AuthenticationWrapper>
                    <MainLayout />
                </AuthenticationWrapper>
            ),
            children: [
                {
                    path: MERGE_DEV_GROUP_MAPPINGS,
                    element: <MergeGroupMappings />,
                },
            ],
        },
    ],
};

export default MergeDevRouter;
