import { API_BASE, APP_ENV } from "./constants";

function APIURL(path: string) {
    return new URL(path, API_BASE[APP_ENV]);
}

export const API_LOGIN = APIURL("login");
export const API_LOGOUT = APIURL("logout");
export const API_BACKOFFICE_BOOSTS_HEADINGS = APIURL("v1/backoffice/boosts/headings");
export const API_BACKOFFICE_BOOSTS_CATEGORIES = APIURL("v1/backoffice/boosts/categories");
export const API_BACKOFFICE_BOOSTS_TEMPLATES = APIURL("v1/backoffice/boosts/templates");
export const API_BACKOFFICE_TRANSLATIONS = APIURL("v1/backoffice/translations");
export const API_BACKOFFICE_TRANSLATIONS_STATUS = APIURL("v1/backoffice/translations/status");

export const API_BACKOFFICE_CUSTOMERS = APIURL("backoffice/customers");

export const API_BACKOFFICE_MERGE_GROUPS = ({ id }: { id: string }) =>
    APIURL(`/v1/provisioning/backoffice/customers/${id}/merge-group-types`);
export const API_CUSTOMER_GROUPS = ({ mongoId }: { mongoId: string | undefined }) =>
    APIURL(`/v1/backoffice/groups/categories?f[customer_id]=${mongoId}`);
export const API_BACKOFFICE_MERGE_ONBOARD = APIURL(
    "/v1/provisioning/backoffice/customers/onboardmerge",
);
export const API_BACKOFFICE_MERGE_GROUP_UPDATE = ({
    customerId,
    groupId,
}: {
    customerId: string;
    groupId: string;
}) => APIURL(`/v1/provisioning/backoffice/customers/${customerId}/merge-group-types/${groupId}`);
