import { Button } from "baseui/button";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import React from "react";
import { useForm, Controller, useFormContext, FormProvider } from "react-hook-form";
import { styled } from "styletron-react";
import { BE_MergeGroup, BE_MoGroup, MergeGroupsFormValues } from "../../types/MergeGroups";
import useUpdateMergeMapping from "../../queries/merge-dev/useUpdateMergeMapping";

const StyledMappingsForm = styled("form", {
    display: "grid",
    "grid-template-columns": "repeat(12, 1fr)",
    gap: "20px",
    textAlign: "center",
    marginTop: "2rem",
});

interface MergeGroupMappingsFormProps {
    groups: BE_MergeGroup[];
    options: BE_MoGroup[];
    defaultValues: MergeGroupsFormValues;
    customerId: string;
}

interface MergeMapItemProps {
    group: BE_MergeGroup;
    options: BE_MoGroup[];
    customerId: string;
}

const MergeMapItem: React.FC<MergeMapItemProps> = ({ group, options, customerId }) => {
    const { watch, control } = useFormContext();
    const { mutate: updateMapping } = useUpdateMergeMapping();

    const value = watch(group.id);

    return (
        <>
            <Input
                overrides={{
                    Root: {
                        style: {
                            gridColumn: "span 5",
                            justifySelf: "center",
                        },
                    },
                }}
                value={group?.type_name}
                disabled
            />
            <Controller
                name={group.id}
                control={control}
                render={({ field: { value, onChange } }) => (
                    <Select
                        overrides={{
                            Root: {
                                style: {
                                    gridColumn: "span 5",
                                    justifySelf: "center",
                                },
                            },
                        }}
                        value={
                            value?.mapped_group_category_id
                                ? [
                                      {
                                          id: value?.mapped_group_category_id,
                                          label: value?.mapped_group_category_label,
                                      },
                                  ]
                                : []
                        }
                        options={options.map((moGroup) => ({
                            id: moGroup?.service_group_id,
                            label: moGroup?.name,
                        }))}
                        onChange={(params) => {
                            // If type clear then we want this to be null
                            if (params.type === "clear") {
                                onChange({
                                    ...value,
                                    mapped_group_category_id: null,
                                    mapped_group_category_label: null,
                                });
                            } else {
                                onChange({
                                    ...value,
                                    mapped_group_category_id: params.value[0].id as string,
                                    mapped_group_category_label: params.value[0].label as string,
                                });
                            }
                        }}
                    />
                )}
            />
            <Button
                type="button"
                overrides={{
                    BaseButton: {
                        style: {
                            gridColumn: "span 2",
                            justifySelf: "center",
                        },
                    },
                }}
                onClick={() => {
                    updateMapping({
                        customerId,
                        groupId: group.id,
                        payload: {
                            id: group.id,
                            category_id: value?.mapped_group_category_id,
                        },
                    });
                }}
            >
                Save
            </Button>
        </>
    );
};

const MergeGroupMappingsForm: React.FC<MergeGroupMappingsFormProps> = ({
    groups,
    options,
    defaultValues,
    customerId,
}) => {
    const methods = useForm({
        defaultValues,
    });

    const { getValues, watch } = methods;

    const values = getValues();
    const existingMappings = Object.values(values)
        .map((mapping) => mapping.mapped_group_category_id)
        .filter(Boolean);

    const remainingOptions = options.filter(
        (option) => !existingMappings.includes(option.service_group_id),
    );

    // This ensures a re-render on form change to ensure values already selected are removed from the options array
    watch();

    return (
        <FormProvider {...methods}>
            <StyledMappingsForm>
                <div className="col-span-5 text-2xl font-bold">Merge Grouping</div>
                <div className="col-span-5 text-2xl font-bold">Mo Grouping</div>
                {groups?.map((group) => (
                    <MergeMapItem
                        key={group.id}
                        group={group}
                        options={remainingOptions}
                        customerId={customerId}
                    />
                ))}
            </StyledMappingsForm>
        </FormProvider>
    );
};

export default MergeGroupMappingsForm;
