import React from "react";
import { Controller, useForm } from "react-hook-form";
import { styled } from "baseui";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { Button } from "baseui/button";
import useOnboard from "../../queries/merge-dev/useOnboard";

const OnboardForm = styled("form", ({ $theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: $theme.sizing.scale800,
}));

interface OnboardFormValues {
    admin: string;
    merge_linked_account_id: string;
}

const MergeOnboardForm = ({ customerId }: { customerId: string }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            admin: "",
            merge_linked_account_id: "",
        },
    });

    const { mutate: postMergeConfig } = useOnboard();

    const handleOnboard = (values: OnboardFormValues) => {
        postMergeConfig({
            admin: values.admin,
            merge_linked_account_id: values.merge_linked_account_id,
            customer_id: customerId,
        });
    };

    return (
        <>
            <span>
                It looks like this customer has not been onboarded yet, to begin mapping groups
                please submit the below form.
            </span>
            <OnboardForm onSubmit={handleSubmit(handleOnboard)}>
                <Controller
                    name="admin"
                    control={control}
                    rules={{
                        required: "This is required",
                        validate: (value) => {
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                            if (!emailRegex.test(value)) {
                                return "Please enter a valid email address";
                            }

                            return true;
                        },
                    }}
                    render={({ field: { name, value, onChange } }) => (
                        <FormControl label="Admin email" error={errors.admin?.message}>
                            <Input
                                placeholder="jackisthebest@mo.work"
                                name={name}
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                error={!!errors.admin}
                            />
                        </FormControl>
                    )}
                />
                <Controller
                    name="merge_linked_account_id"
                    control={control}
                    rules={{ required: "This is required" }}
                    render={({ field: { name, value, onChange } }) => (
                        <FormControl
                            label="Merge linked account ID"
                            error={errors?.merge_linked_account_id?.message}
                        >
                            <Input
                                placeholder="1234-567-890"
                                name={name}
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                error={!!errors.merge_linked_account_id}
                            />
                        </FormControl>
                    )}
                />
                <Button type="submit">Submit</Button>
            </OnboardForm>
        </>
    );
};

export default MergeOnboardForm;
