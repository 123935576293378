import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { API_CUSTOMER_GROUPS } from "../../constants/api-endpoints";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";
import { BE_MoGroup } from "../../types/MergeGroups";

const useGetMoGroups = ({
    customerMongoId,
}: {
    customerMongoId: string | undefined;
}): UseQueryResult<BE_MoGroup[]> =>
    useQuery(
        ["mo-groups", customerMongoId],
        () => {
            const url = new URL(API_CUSTOMER_GROUPS({ mongoId: customerMongoId }));

            const request = new AuthenticatedMoApiRequest(url);

            return FetchResponse(request);
        },
        {
            enabled: !!customerMongoId,
        },
    );

export default useGetMoGroups;
