import { useMutation } from "@tanstack/react-query";
import { API_BACKOFFICE_MERGE_GROUP_UPDATE } from "../../constants/api-endpoints";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";
import { addErrorToast, addSuccessToast } from "../../helpers/addToast";

interface UseUpdateMappingArgs {
    groupId: string;
    customerId: string;
    payload: {
        id: string;
        category_id: string;
    };
}

const useUpdateMergeMapping = () =>
    useMutation(
        async ({ groupId, customerId, payload }: UseUpdateMappingArgs) => {
            const url = new URL(API_BACKOFFICE_MERGE_GROUP_UPDATE({ groupId, customerId }));

            const request = new AuthenticatedMoApiRequest(url, {
                method: "PUT",
                payload: payload as Partial<FormData>,
            });

            return FetchResponse(request);
        },
        {
            onSuccess: () => {
                addSuccessToast("Mapping updated successfully!");
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );

export default useUpdateMergeMapping;
