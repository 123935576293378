import React from "react";
import { RouteObject } from "react-router-dom";
import Login from "../routes/Login";
import { ACCESS_DENIED, CONNECT_WITH_TOKEN_ROOT, LOGOUT, LOGIN, ROOT } from "./routes";
import ConnectWithToken from "../routes/connect-with-token/ConnectWithToken";
import Logout from "../routes/Logout";
import AccessDenied from "../routes/AccessDenied";
import RootPage from "../routes/RootPage";
import BoostCMSRouter from "./routers/BoostCMSRouter";
import TranslationRouter from "./routers/TranslationRouter";
import MergeDevRouter from "./routers/MergeDevRouter";

const AppRoutes: RouteObject[] = [
    {
        path: ROOT,
        children: [
            { index: true, element: <RootPage /> },
            {
                path: LOGIN,
                element: <Login />,
            },
            {
                path: LOGOUT,
                element: <Logout />,
            },
            { path: ACCESS_DENIED, element: <AccessDenied /> },
            {
                path: `${CONNECT_WITH_TOKEN_ROOT}/:token`,
                element: <ConnectWithToken />,
            },
            // Boost CMS Routes
            BoostCMSRouter,
            // TranslationRoutes,
            TranslationRouter,
            // Merge.dev Group Mappings
            MergeDevRouter,
        ],
    },
];

export default AppRoutes;
