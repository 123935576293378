import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { API_BACKOFFICE_CUSTOMERS } from "../../constants/api-endpoints";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";
import { BE_BackofficeCustomers } from "../../types/BE_Customers";

const useGetCustomers = (): UseQueryResult<BE_BackofficeCustomers> =>
    useQuery([API_BACKOFFICE_CUSTOMERS], async () => {
        const url = new URL(API_BACKOFFICE_CUSTOMERS);

        url.searchParams.append("type", "standard");
        url.searchParams.append("state", "active");

        const request = new AuthenticatedMoApiRequest(url);

        return FetchResponse(request);
    });

export default useGetCustomers;
